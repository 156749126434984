onGigyaServiceReady = (function (existingGSR) {
  /*
    This package helps genericize the handling of authentication processes by doing two basic things:
    1) determine and persist the initial context for authentication (e.g., SAML, OIDC, generic)
    2) finalize the authentication flow back to the requestor

    THIS FILE SHOULD BE INCLUDED IN GIGYA PROJECTS, BUT NOT EDITED!
  */
  return (function () {
    // check for gigya dependencies
    !function (a, b) {
      a = "[" + a + "]", b && void 0 === window.gigya && console.error(a + " The gigya object does not exist."), console.log(a + " Loading..."), "function" == typeof existingGSR && (console.log(a + "... Executing prior onGigyaServiceReady functions"), existingGSR(), console.log(a + "... Now continuing with this onGigyaServiceReady"))
    }("authn.js", !0)

    try {
      if (!gigya._myContext) {
        gigya._myContext = {};
      }
      if (!gigya._myContext.authn) {
        gigya._myContext.authn = {};
      }
      if (gigya._myContext.authn.context) {
        throw {
          error: '[authn.js] gigya._myContext.authn.context already exists!  Bailing out...',
          'existing object': gigya._myContext.authn.context
        };
      }
    } catch (ee) {
      throw ee;
    }

    // create a context for the duration of the register/login flow
    gigya._myContext.authn.context = (function () {

      context = {
        init: function () {
          console.log('[authn.js] Initializing context...')
          URLContext = (function (params) {
            /*
              generally we are parsing authn parameters for either SAML, OIDC or generic
              acceptable SAML params:
                protocol = 'saml'
                spName = name of SAML SP
              acceptable OIDC params:
                protocol = 'oidc'
                rp = name of OIDC RP
                proxyPageURI = URI to redirect back to proxy page after login
              generic params:
                redirectURL = (optional) URL to redirect to after login
            */

            _context = {
              initialParams: window.location.search
            };

            try {
              _context['protocol'] = (params['samlContext'] ? 'saml' : (params['oidc'] ? 'oidc' : undefined));
              switch (_context['protocol']) {
                case 'saml':
                  _context['client'] = params['spName'] || undefined;
                  if (!_context.client) {
                    throw 'No SAML SP defined'
                  }
                  break;

                case 'oidc':
                  if (!params['rp']) {
                    params['rp'] = (function () { // get clientID from gigyaCache
                      var ss = sessionStorage['gigyaCache'];
                      if (ss) {
                        return ((JSON.parse(ss))['gig_oidcContext_' + gigya.apiKey].response)[((JSON.parse(ss))['gig_oidcContext_' + gigya.apiKey].response).length - 1].clientID;
                      }
                    })();
                  }
                  _context['client'] = params['rp'] || undefined;
                  if (!_context.client) {
                    throw 'No OIDC RP defined'
                  }

                  _context['proxyPageURI'] = params['redirectURL'] || undefined;
                  if (!_context.proxyPageURI) {
                    throw 'OIDC proxy page URI is defined'
                  }

                  break;

                default:
                  _context['redirectURL'] = params['redirectURL'];
                  break;
              }
            } catch (e) {
              throw {'[authn.js]': e};
            }
            return (_context);
          })(gigya.utils.URL.getParamsFromURL(document.URL));

          sessionStorage.setItem('gigya._authnContext', JSON.stringify(URLContext));
          console.log('[authn.js] context updated', context.get());
          return (context.get());
        }

        , set: function (items) {
          ss = context.get();

          if (typeof items === 'object' && !(items instanceof Array)) {
            Object.keys(items).forEach(key => {
              ss[key] = items[key];
            });
          } else {
            throw {
              '[authn.js] Error setting values; expecting {name:value} pair(s) but got this: ': {
                items: items,
                typeof: typeof items
              }
            }
          }

          sessionStorage.setItem('gigya._authnContext', JSON.stringify(ss));
          return (context.get());
        }

        , get: function () {
          return (JSON.parse(sessionStorage.getItem('gigya._authnContext')));
        }

        // login is propagated back to requesting client after intermediate business logic is finished
        // - typically called from onLogin handler after login logic is properly complete
        , finalizeLogin: function (redirectURL) { // called from here and from screen-set js
          // get a local copy for immediate use while sessionStorage is cleared
          _context = context.get();

          // clear the authn context
          sessionStorage.removeItem(['gigya._authnContext']);

          switch (_context.protocol) { // user logged in
            case 'saml': // redirect back to SP
              gigya.fidm.saml.continueSSO({
                callback: function (response) {
                  console.log(JSON.stringify(response))
                }
              })
              break
            case 'oidc': // redirect back to RP
              let url = gigya.utils.URL.addParamsToURL(
                _context.proxyPageURI
                , {mode: 'afterLogin'}
              );
              window.location = url
              break
            default:
              window.location = "profile.html"
              break
          }

          return (true);
        }
      };

      // must preserve initial authn context in case of redirection during register/login flow
      if (!sessionStorage.getItem('gigya._authnContext') || (gigya.utils.URL.getParamsFromURL(document.URL))['init']) {
        // need to know  current context so we can check required attributes and process login correctly
        context.init();
      } else {
        console.log('[authn.js] Using existing context', context.get());
      }

      return (context);
    })();
  })
})((typeof onGigyaServiceReady === 'undefined') ? null : onGigyaServiceReady);
